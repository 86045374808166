import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#23374C",
      light: "#798DA8",
      contrast: "#ffffff",
    },
    background: {
      main: "#F3F5F1",
      light: "#f8f8f8",
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0, // Extra-small screens
      sm: 576, // Small screens (mobile)
      md: 768, // Medium screens (tablets)
      lg: 992, // Large screens (small laptops)
      xl: 1200, // Extra-large screens (desktops)
      xxl: 1440, // Extra-extra-large screens (large desktops)
      xxxl: 2048, // Wide screen desktops and TVs
    },
  },
});

export default theme;
