import { styled } from "@mui/material/styles";
import logo from "../../Logos/GoalunitLogo_NavyHorisontal.svg";

const LogoContainer = styled("div")(({ theme }) => ({
  width: "200px",
  height: "44px",
  overflow: "hidden",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  left: "25px",

  [theme.breakpoints.down("sm")]: {
    width: "49px",
    left: "15px",
  },
}));

const StyledImg = styled("img")(() => ({
  width: "200px",
  height: "43.4px",
  objectFit: "cover",
  objectPosition: "left bottom",
}));

const GoalUnitLogo = () => (
  <LogoContainer>
    <StyledImg src={logo} alt="Goalunit's logo" />
  </LogoContainer>
);

export default GoalUnitLogo;
