import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Element } from 'react-scroll';
import { useLocation, Routes, Route } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CircularProgress, ThemeProvider } from '@mui/material';
import { userEntitlementStore } from '../src/State/UserEntitlementStore';
import { profileStore } from '../src/State/ProfileStore';
import Navbar from './Components/Navbar/Navbar';
import ClubOverview from './Datapage/ClubOverview';
import UnavailableService from './Components/Unavailable';
//import SquadPage from './Squadpage/SquadPageOverview/SquadPageOverview'
import Profile from './Authorization/profile';
import Services from './Landingpage/Services';
import About from './Landingpage/About';
import AM from './Landingpage/AM';
import Contact from './Landingpage/Contact';
import Home from './Landingpage/Home';
import Team from './Landingpage/Team';
import VerifyEmail from './Authorization/VerifyEmail';
import TermsAndConditions from './Components/TermsAndConditions';
import useEmailVerificationCheck from './Authorization/isEmailVerified';
import BlurredOverlay from './Components/BlurredOverlay/BlurredOverlay';
import SubscriptionConfirmation from './Components/SubscriptionConfirmation/SubscriptionConfirmation';
import isAdministrator from './Authorization/isAdministrator';
import './Style/style.css';
import './Style/am_style.css';
import theme from './Style/theme';
import { routes } from './Util/routes';

function LandingPage() {
  return (
    <div className='landing-page'>
      <main>
        <Element name='home'><Home /></Element>
        <Element name='am'><AM /></Element>
        <Element name='services'><Services /></Element>
        <Element name='about'><About /></Element>
        <Element name='team' ><Team /> </Element>
        <Element name='contact'><Contact /></Element>
      </main>
    </div>
  )
}

function Loading() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  );
}

const ProtectedRoute = observer(({ component: Component, featureId: FeatureId }) => {
  const { isLoading } = useAuth0();
  const [ hasAccess, setHasAccess ] = useState(false); 

  useEffect(() => {
    if(userEntitlementStore.isLoaded) {
      const access = userEntitlementStore.checkFeatureAccess(FeatureId);
      setHasAccess(access);
    }
  }, [userEntitlementStore.entitlements, userEntitlementStore.isLoaded, FeatureId]);

  if (isLoading ||  !userEntitlementStore.isLoaded) {
    return <Loading />;
  }

  const ComponentWithAuth = withAuthenticationRequired(Component, {
    onRedirecting: () => <Loading />,
  });

  
  return (
    hasAccess || isAdministrator(profileStore.userEmail) ? (
      <ComponentWithAuth />
    ) : (
      <BlurredOverlay />
    )
  );
});


function App() {
  const location = useLocation();
  const [bgColor, setBgColor] = useState('#23374C');

  useEffect(() => {
    if (location.pathname.startsWith(routes.am.base)) {
      setBgColor('#F3F5F1');
    } else {
      setBgColor('#23374C');
    }
  }, [location.pathname]);

  useEffect(() => {
    // Activate polling for chargebee entitlements after being redirected to checkout portal
    if (localStorage.getItem('shouldStartPolling') === 'true') {
      userEntitlementStore.startPolling();
      localStorage.removeItem('shouldStartPolling');
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
    <div style={{ backgroundColor: bgColor, minHeight: '100vh' }}>
      {useEmailVerificationCheck() ? (
        <VerifyEmail />
      ) : (
        <div>
          <Navbar />
          <TransitionGroup>
            <CSSTransition key={location.pathname} classNames="fade" timeout={100}>
              <Routes location={location}>
                <Route path={routes.start.base} element={<LandingPage />} />
                <Route path={"/"  + routes.start.home} element={<Home />} />
                <Route path={"/"  + routes.start.services} element={<Services />} />
                <Route path={"/"  + routes.start.about} element={<About />} />
                <Route path={"/"  + routes.start.contact} element={<Contact />} />
                <Route path={"/"  + routes.start.am} element={<AM />} />
                <Route path={routes.am.clubSummary} element={<ProtectedRoute component={ClubOverview} featureId="club-summary" />} />
                
                <Route path={routes.am.squadPage} element={<ProtectedRoute component={UnavailableService} featureId="squad-page" />} />
                <Route path={routes.am.profile} element={<ProtectedRoute component={Profile} />} />
                <Route path={routes.am.termsAndConditions} element={<TermsAndConditions />} />
                <Route path={routes.am.subscriptionConfirmation} element={<SubscriptionConfirmation/>} />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </div>
      )}
    </div>
    </ThemeProvider>
  );
}

export default App;