import { Box, Typography, styled } from "@mui/material";

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Montserrat, sans-serif',
}));

const UnavailableService = () => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <CustomTypography variant="h4" gutterBottom sx={{ mt: 10, mb: 2 }}>
        Service temporarily unavailable
      </CustomTypography>
      <CustomTypography variant="h6" gutterBottom sx={{ mb: 5 }}>
        We're working on resolving the issue as fast as possible.
      </CustomTypography>
    </Box>
  );
};


export default UnavailableService;
