import React, { useState, useEffect, useRef } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart, registerables } from 'chart.js';
import datalabels from 'chartjs-plugin-datalabels';
import { exportPdfStore } from '../State/ExportPdfStore';
import { transferKpiComparsionDescription } from '../Util/ChartDescriptions';
import '../Style/am_style.css';
import './Datapage.css'

Chart.register(...registerables);

const TransferKPIComparison = (props) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const chartRef = useRef(null);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    scales: {
      y: {
        min: 0,
        beginAtZero: true
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            const value = context.raw;
            const originalValue = props.data[context.dataIndex][context.dataset.label === 'Club Transfer KPI' ? 'NewTeamTransferKPI' : 'NewLeagueAverageTransferKPI'];
            
            if (value === null) {
              label += 'N/A';
            } else if (originalValue > 60) {
              label += originalValue.toFixed(2);
            } else {
              label += value.toFixed(2);
            }
            return label;
          }
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y.toFixed(2);
            } else {
              label += 'N/A';
            }
            return label;
          }
        }
      }
    },
    animation: {
      onComplete: function() {
        if (chartRef.current) {
          const base64Image = chartRef.current.toBase64Image();
          exportPdfStore.setTransferKPIComparisonData(base64Image);
        }
      }
    }
  };
  
  const [uniqueSeasons, setUniqueSeasons] = useState([]);


  useEffect(() => {
    const backgroundcolors = [
      getComputedStyle(document.documentElement).getPropertyValue('--element-color-3'),
      getComputedStyle(document.documentElement).getPropertyValue('--element-color-2')
    ].map(color => {
      const rgbaColor = color.replace(')', ', 0.5)').replace('rgb', 'rgba');
      return rgbaColor;
    });
    
    const loadData = (data) => {
      var sortedData = data.filter(item => item.SeasonName);
      sortedData.sort((a, b) => a.SeasonName.localeCompare(b.SeasonName));
      const processedData = sortedData.map(KPI => ({
        ...KPI,
        NewTeamTransferKPI: KPI.NewTeamTransferKPI === 0 ? null : KPI.NewTeamTransferKPI,
        NewLeagueAverageTransferKPI: KPI.NewLeagueAverageTransferKPI === 0 ? null : KPI.NewLeagueAverageTransferKPI
      }));

      let uniqueSeasons = [...new Set(sortedData.map(item => item.SeasonName))];
      setUniqueSeasons(uniqueSeasons);

      const labels = processedData.map(KPI => KPI.SeasonName);
      const datasets = [
        {
          label: 'Club Transfer KPI',
          data: processedData.map(KPI => KPI.NewTeamTransferKPI !== null ? Number(KPI.NewTeamTransferKPI.toFixed(2)) : null),
          borderColor: backgroundcolors[0],
          backgroundColor: backgroundcolors[0],
          tension: 0.4 // This enables Bezier curve interpolation
        },
        {
          label: 'League average Transfer KPI',
          data: processedData.map(KPI => KPI.NewLeagueAverageTransferKPI !== null ? Number(KPI.NewLeagueAverageTransferKPI.toFixed(2)) : null),
          borderColor: backgroundcolors[1],
          backgroundColor: backgroundcolors[1],
          tension: 0.4 // This enables Bezier curve interpolation

        }
      ];
      setChartData({ labels, datasets });
    }; 
  
    loadData(props.data);
  }, [props.data]);

  return (
    <div className="chart-section">
      {uniqueSeasons.length > 1 ? (
        <div style={{ width: '65%', margin: 'auto' }}>
          <Line data={chartData} options={chartOptions} width={500} height={450} ref={chartRef} />
        </div>
      ) : (
        <div style={{ width: '40%', margin: 'auto' }}>
          <Bar data={chartData} options={chartOptions} width={500} height={450} ref={chartRef} plugins={[datalabels]}/>
        </div>
      )}
      <div className="description-section">
        <div className="description-container">   
          <span style={{ fontWeight: 'bold' }}>Description: </span> <br></br>
           {transferKpiComparsionDescription}.
        </div>
      </div>
    </div>
  );
};

export default TransferKPIComparison;


