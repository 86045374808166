import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
import '../Style/style.css';
import '../Style/am_style.css';
import { routes } from '../Util/routes';


function AM() {
    
  const { isAuthenticated, loginWithRedirect } = useAuth0();

    return (
      <Element name="am" className="am" id="am">
        <div className="am-container">
          <div className='am-list'>
            <div className='am-item'>
              <h1>Assistant Manager</h1>
            </div>
            <div className='am-item'>

              {isAuthenticated ? <Link to={routes.am.clubSummary}>  <button className="big-button">

                Open Assistant Manager
              </button> </Link>: (
              <button className="big-button" onClick={() => loginWithRedirect()}>
                Log In / Sign up
              </button>
              )}  

            </div>
          </div>

        </div>
      </Element>
    );
  }

export default AM;