import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { auth0Service } from '../Util/Auth0Client';
import { dashboardStore } from './DashboardStore';


export default class SeasonStore {
    data = [];
    selectedSeason = null;
    isLoading = false;
    error = null;
    
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, 
            { 
                name: 'SeasonStore', 
                properties: ['data', 'selectedSeason'], 
                storage: window.localStorage
            }
        );
    }

    loadSeasons = async () => {
        this.setLoading(true);
        this.setError(null);

        try {
            const token = await auth0Service.getToken();
            const header = { Authorization: `Bearer ${token}` };
            const response = await fetch(
                '/api/getclubseasons', 
                { 
                    headers: header, 
                    credentials: 'include' 
                }
            );
            const seasons = await response.json();
            
            runInAction(() => {
                this.setData(seasons);
                if(seasons.length > 0 && this.selectedSeason !== seasons[0]) {
                    this.setSelectedSeason(seasons[0]);
                }
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    handleChange = async (event) => {
        const selectedSeasonId = event.target.value;
        const selectedSeason = this.data.find(season => season.SeasonId === selectedSeasonId);

        runInAction(() => {
            this.setSelectedSeason(selectedSeason);
        });
        try {
            const token = await auth0Service.getToken();
            const headers = { 
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
             };

            const url = `/api/gettransferkpi?seasonId=${selectedSeasonId}`;
            const response = await fetch(url, {
                method: 'GET',
                headers,
                credentials: 'include',
            });

            const transferKPIData = await response.json();
            
            dashboardStore.setNewKPIData(transferKPIData); 

        } catch(error) {
            runInAction(() => {
                this.setError(error.message);
            });
        }
    }

    setData(newData) {
        this.data = newData;
    }

    setSelectedSeason(season) {
        this.selectedSeason = season;
    }

    setLoading(loading) {
        this.isLoading = loading;
    }

    setError(error) {
        this.error = error;
    }
}

export const seasonStore = new SeasonStore();