import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { dashboardStore } from '../State/DashboardStore';
import { impersonationStore } from '../State/ImpersonationStore';
import { profileStore } from '../State/ProfileStore';
import { seasonStore } from '../State/SeasonStore';
import { userEntitlementStore } from '../State/UserEntitlementStore';
import { squadStore } from '../State/SquadStore';
import { positionsStore } from '../State/PositionsStore';
import { countriesStore } from '../State/CountriesStore';
import { routes } from '../Util/routes';

const AuthProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = async (appState) => {
    try {
      await impersonationStore.loadDropDownOptions();
      await profileStore.init();
      await userEntitlementStore.GetUserEntitlements();
      seasonStore.loadSeasons();
      dashboardStore.fetchData();
      positionsStore.fetchData();
      countriesStore.fetchData();
      //squadStore.fetchData();
      navigate(appState?.returnTo || window.location.pathname);
    } catch (error) {
      console.error('Error during onRedirectCallback:', error);
      // Handle the error appropriately
    }
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin + routes.am.profile,
        audience: process.env.REACT_APP_SERVER,
        scope: 'openid profile email offline_access',
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProviderWithHistory;