import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart, registerables } from 'chart.js';
import { exportPdfStore } from '../State/ExportPdfStore';
import { playTimeDistributionDescription } from '../Util/ChartDescriptions';
import '../Style/am_style.css';
import waterMarkPlugin from '../Components/Watermark';

Chart.register(...registerables);

const PlayTimeDistribution = (props) => {
  const [chartData, setChartData] = useState({ datasets: [] });
  const [dataLoaded, setDataLoaded] = useState(false);

  const chartRef = useRef(null);

  const backgroundcolors = [
    getComputedStyle(document.documentElement).getPropertyValue('--element-color-1'),
    getComputedStyle(document.documentElement).getPropertyValue('--element-color-2'),
    getComputedStyle(document.documentElement).getPropertyValue('--element-color-3'),
    getComputedStyle(document.documentElement).getPropertyValue('--element-color-4')
  ].map(color => {
    const rgbaColor = color.replace(')', ', 0.5)').replace('rgb', 'rgba');
    return rgbaColor;
  });

  const loadData = (jsonData) => {
    const maxMatchesInSeason = {};
    const rankedSeasonalData = [];


    const ageCategories = new Set();

    jsonData.forEach(item => {
      ageCategories.add(item.AgeCategory);
    });

    const ageCategoriesOrder = Array.from(ageCategories);

    ageCategoriesOrder.sort((a, b) => {
      const getLowestNumber = str => {
        const match = str.match(/\d+/);
        return match ? parseInt(match[0]) : 0;
      };
      
      return getLowestNumber(a) - getLowestNumber(b);
    });

    jsonData.forEach(item => {
      const { SeasonName, MatchNo } = item;
      if (!maxMatchesInSeason[SeasonName] || MatchNo > maxMatchesInSeason[SeasonName]) {
        maxMatchesInSeason[SeasonName] = MatchNo;
      }
    });

    jsonData.forEach(item => {
      const { SeasonName, MatchNo } = item;
      if (MatchNo === maxMatchesInSeason[SeasonName]) {
        rankedSeasonalData.push(item);
      }
    });

    const labels = Array.from(new Set(rankedSeasonalData.map(item => item.SeasonName))).sort();
    const datasets = ageCategoriesOrder.map((ageCategory, index) => {
      return {
        label: ageCategory,
        data: labels.map(season => {
          const item = rankedSeasonalData.find(d => d.SeasonName === season && d.AgeCategory === ageCategory);
          return item ? item.PartOfPlayingTime : 0;
        }),
        fill: true,
        borderColor: backgroundcolors[index],
        backgroundColor: backgroundcolors[index],
        borderColor: '#000000', 
        borderWidth: 0.5, 
        tension: 0.4 // This enables Bezier curve interpolation
      };
    });

    setChartData({
      labels: labels,
      datasets: datasets,
    });

    setDataLoaded(true); 
  };

  useEffect(() => {
    loadData(props.data);
  }, [props.data]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Season',
        },
      },
      y: {
        title: {
          display: true,
          text: '%',
        },
        min: 0,
        max: 100,
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 20,
          font: {
            size: 16 // Adjust this value to change the font size
          }
        }
      }, 
      waterMarkPlugin: {
        afterDatasetsDraw: (chart, args, plugins) => {
          const img = new Image(); 
          img.src='/Black-logo.svg'; 
  
          const { ctx, chartArea: {top,  left,  width, height}} = chart; 
  
          ctx.save(); 
          ctx.globalAlpha = 0.1;
  
          const centerX = left + width / 2 - (0.15*width); 
          const centerY = top + height / 2 - (0.15*width);
  
          ctx.drawImage(img, centerX, centerY, 0.3*width, 0.3*width);
          ctx.restore(); 
          }
      }
    },
    animation: {
      onComplete: function() {
        if (chartRef.current) {
          const base64Image = chartRef.current.toBase64Image();
          exportPdfStore.setPlayTimeDistributionData(base64Image);
        }
      }
    }
  };

  return (
    <div className="chart-section">
      <div style={{ width: '65%', margin: 'auto' }}>
        {dataLoaded && <Line data={chartData} options={chartOptions} width={600} height={450} ref={chartRef} />}
      </div>
      <div className="description-section">
        <div className='description-container'>
          <span style = {{fontWeight: 'bold'}} >Description: </span> <br></br>
            {playTimeDistributionDescription}
        </div>
      </div>
    </div>
  );
};

export default PlayTimeDistribution;
