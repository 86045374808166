import React from 'react';
import { Element } from 'react-scroll';
import '../Style/style.css';
import '../Style/am_style.css';


function About() {
    return (
      <Element name="about" className="about" id="about">
        <div className="about-container">
          <div className="about-list">
            <div className="about-item">
              <h1>About</h1>
            </div>
            <div className="about-item">
              <p>We founded Goalunit with the purpose of revolutionizing the decision-making within <br className='regular-break'/>European football, through delivering relevant, contextual data insights. Our goal is to <br className='regular-break'/> support clubs in their journey to become over-performing on and off the pitch.</p>
            </div>
          </div>
        </div>
      </Element>
    );
  }

export default About;