import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { auth0Service } from '../Util/Auth0Client';

export default class DashboardStore {
    data = {};
    isLoading = false;
    error = null;
    dashboardType = 'dashboard'

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, 
            { 
                name: 'ClubSummaryStore', 
                properties: ['data', 'dashboardType'], 
                storage: window.localStorage
            }
        );
    }

    fetchData = async () => {
        this.setLoading(true);
        this.setError(null);
        
        try {
            const token = await auth0Service.getToken();
            const header = { Authorization: `Bearer ${token}` };
            const response = await fetch(
                '/api/clubsummary', 
                {
                    headers: header, 
                    credentials: 'include' 
                }
            );
            const dashboardData = await response.json();
            runInAction(() => {
                this.setData(dashboardData);
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    setData(newData) {
        this.data = newData;
    }

    setLoading(loading) {
        this.isLoading = loading;
    }

    setError(error) {
        this.error = error;
    }

    handleDashboardTypeChange = (event, type) => {
        if(type !== null) {
            runInAction(() => {
                this.dashboardType = type;
            });
        }
    }

    getDashboardType = () => {
        return this.dashboardType;
    }

    setNewKPIData(newData) {
        runInAction(() => {
            this.data = { ...this.data, KPI: newData };
        });
    }
}

export const dashboardStore = new DashboardStore();